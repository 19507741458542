import Header from '../components/Header'


export default function NoPage() {
    return (
        <>
        <Header />
        <h2> Error 404: Not Found</h2>
        </>
    )
}